<template>
  <v-footer id="dashboard-core-footer">
    <v-container>
      <v-row
        class="position-relative"
        align="center"
        justify="center"
        no-gutters
      >
        <!-- <v-spacer class="hidden-sm-and-down" /> -->
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column align-center justify-center"
        >
          <!-- <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
            &copy; {{currentYear}}, made by
            by
            <a
              href="https://www.blackvt.com"
            >Black Vault</a>.
          </div>-->
          <v-img src="@/assets/logo_footer.png" contain max-width="150"></v-img>
          <span class="caption secondary--text text--darken-3 text-center w-100"
            > {{ versionNumber }}</span
          >
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import Config from "../../../services/config";

export default {
  name: "DashboardCoreFooter",
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },
    versionNumber() {
      return Config.versionNumber;
    },
  },
};
</script>
<style scoped>
.position-relative {
  position: relative;
}
</style>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
